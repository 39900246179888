import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './shared/pages/not-found/not-found.component';

const routes: Routes = [
  { path: 'afiliacion', loadChildren: () => import('./registro/registro.module').then( e => e.RegistroModule ) },
  { path: 'siniestro', loadChildren: () => import('./siniestro/siniestro.module').then(e => e.SiniestroModule) },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
