<div id="column-1" class="col-md-12 portlet-column portlet-column-only">
    <div class="portlet-dropzone portlet-column-content portlet-column-content-only">
        <div class="portlet-boundary portlet-boundary_NowoAfiliadosWeb_NowoAfiliadosWebPortlet_ portlet-static portlet-static-end portlet-decorate">
            <section class="portlet">
                <div class="portlet-content">
                    <div class="portlet-content-container">
                        <div class="portlet-body">
                            <div class="descripcionAM ag-o-rich-text">
                                <section>
                                    <div class="ag-l-wrapper ag-l-border-top">
                                        <div class="ag-o-header">
                                            <div class="ag-o-header__inner">
                                                <h2 class="ag-o-title" style="font-size: 3rem; border: none;">Página no encontrada </h2>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>